<template>
    <div>
        <v-icon color="rgb(87 75 113)" class="ml-6 menu hidden-md-and-up" size="1.8rem" @click="localDrawer = !localDrawer">
            mdi-menu
        </v-icon>

        <!-- desktop -->
        <v-navigation-drawer
            v-model="drawer"
            class="background-sidebar hidden-sm-and-down"
            :mini-variant.sync="mini"
            permanent
            width="256"
        >
        <v-list-item class="mt-2 mb-2">
            <v-list-item-content>
                <v-btn text to="/panel/admin" class="logo-base">
                    <img v-if="!mini" src="../../../assets/logo/harmony-logo.png" class="logo">
                    <img v-else src="../../../assets/logo/1-10.png" class="logo-2">
                </v-btn>
            </v-list-item-content>

            <v-btn icon @click.stop="mini = !mini">
                <v-icon color="#BE82FE" class="ml-12">mdi-chevron-left-box</v-icon>
            </v-btn>
        </v-list-item>

      <v-divider></v-divider>

      <v-list dense nav class="mt-3">
                <v-list-item-group
                    v-model="selectedItem"
                    mandatory
                    class="nav-items"
                    active-class="active-item"
                >
                    <!-- Iterando sobre los items del menú -->
                    <div v-for="(item, index) in items" :key="`item-${index}`">
                        <!-- Item principal -->
                        <v-list-item
                            v-if="!item.children"
                            :to="item.url"
                            exact
                            class="nav-link"
                            :class="{ 'active-item': isExactActive(item.url), 'inactive-item': !isExactActive(item.url) }"
                        >
                            <v-list-item-icon class="mr-0">
                                <v-icon :class="{ 'active-icon': isExactActive(item.url), 'inactive-icon': !isExactActive(item.url) }">{{ item.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content class="col-10 text-left">
                                <v-list-item-title class="ml-4">{{ item.title }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <!-- Item con submenú -->
                        <v-list-group
                            v-else
                            no-action
                            class="nav-link"
                            :value="isExactActiveSubmenu(item.children)"
                            prepend-icon
                        >
                            <template v-slot:activator>
                                <v-list-item-icon class="mr-0">
                                    <v-icon class="inactive-icon group-color">{{ item.icon }}</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content class="col-10 text-left">
                                    <v-list-item-title class="ml-4 group-color">{{ item.title }}
                                        <v-badge 
                                        v-if="item.title === 'Retiros' && withdrawalsCountPending > 0 || item.title === 'Medios' && mediaCountPending > 0" 
                                        color="#f29339"
                                        class="badge-aligned">
                                        <template v-slot:badge>
                                            <h4>!</h4>
                                        </template>
                                        </v-badge>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </template>

                            <v-list-item
                                v-for="(child, i) in item.children"
                                :key="`child-${index}-${i}`"
                                :to="child.url"
                                class="links"
                                :class="{ 'active-item': isExactActive(child.url), 'inactive-item': !isExactActive(child.url) }"
                            >
                            <v-list-item-title class="text-left">
                                -&nbsp;&nbsp;&nbsp;
                                {{ child.title }}
                                <v-badge v-if="child.url === '/panel/admin/pendings' && withdrawalsCountPending > 0 || child.url === '/panel/admin/media-pending' && mediaCountPending > 0" color="#f29339" :content="child.url === '/panel/admin/pendings' ? withdrawalsCountPending : mediaCountPending"    class="badge-aligned"/>
                            </v-list-item-title>
                            </v-list-item>
                        </v-list-group>
                    </div>

                    <v-list-item
                        class="inactive-item"
                        @click="logout()"
                    >
                        <v-list-item-icon class="mr-0">
                            <v-icon class="inactive-icon">mdi-logout</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content class="col-10 text-left">
                            <v-list-item-title class="ml-4">Cerrar sesión</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>

        <!-- Mobile -->
        <v-navigation-drawer
            v-model="localDrawer"
            app
            class="background-sidebar hidden-md-and-up"
            width="256"
        >
            <v-list-item class="mt-3 mb-2">
                <v-list-item-content>
                    <v-btn text to="/panel/admin" class="logo-base">
                        <img src="../../../assets/logo/harmony-logo.png" class="logo">
                    </v-btn>
                </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list dense nav class="mt-3">
                <v-list-item-group
                    v-model="selectedItem"
                    mandatory
                    class="nav-items"
                    active-class="active-item"
                >
                    <div v-for="(item, index) in items" :key="`item-mobile-${index}`">
                        <!-- Item principal -->
                        <v-list-item
                            v-if="!item.children"
                            :to="item.url"
                            exact
                            class="nav-link"
                            :class="{ 'active-item': isExactActive(item.url), 'inactive-item': !isExactActive(item.url) }"
                            :aria-selected="isExactActive(item.url) ? 'true' : 'false'"
                        >
                            <v-list-item-icon class="mr-0">
                                <v-icon :class="{ 'active-icon': isExactActive(item.url), 'inactive-icon': !isExactActive(item.url) }">{{ item.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content class="col-10 text-left">
                                <v-list-item-title class="ml-4">{{ item.title }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <!-- Item con submenú -->
                        <v-list-group
                            v-else
                            no-action
                            class="nav-link"
                            :value="isExactActiveSubmenu(item.children)"
                            prepend-icon
                        >
                            <template v-slot:activator>
                                <v-list-item-icon class="mr-0">
                                    <v-icon class="inactive-icon group-color">{{ item.icon }}</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content class="col-10 text-left">
                                    <v-list-item-title class="ml-4 group-color">{{ item.title }}</v-list-item-title>
                                </v-list-item-content>
                            </template>

                            <v-list-item
                                v-for="(child, i) in item.children"
                                :key="`child-mobile-${index}-${i}`"
                                :to="child.url"
                                class="nav-link pl-13"
                                :class="{ 'active-item': isExactActive(child.url), 'inactive-item': !isExactActive(child.url) }"
                            >
                                <v-list-item-content class="pl-0">
                                    <v-list-item-title class="text-left">-&nbsp;&nbsp;&nbsp;{{ child.title }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-group>
                    </div>

                    <v-list-item
                        class="inactive-item"
                        @click="logout()"
                    >
                        <v-list-item-icon class="mr-0">
                            <v-icon class="inactive-icon">mdi-logout</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content class="col-10 text-left">
                            <v-list-item-title class="ml-4">Cerrar sesión</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>

    </div>
</template>

<script>
import axios from '@/axios.js';

export default {
    metaInfo: {
        titleTemplate: '%s - Harmony Fan',
        title: 'Admin Panel'
    },
    data() {
        return {
            localDrawer: false, // Crear una copia local de la prop 'drawer'
            drawer: false,
            mini: false,
            items: [
                { title: 'Panel', icon: 'mdi-home', url: '/panel/admin' },
                {
                    title: 'Enlaces',
                    icon: 'mdi-link-box',
                    children: [
                        { title: 'Listado', url: '/panel/admin/link/list' },
                        { title: 'Conversiones', url: '/panel/admin/links' },
                        { title: 'Plataformas', url:'/panel/admin/platforms'},
                    ]
                },
                {
                    title: 'Usuarios',
                    icon: 'mdi-account-multiple',
                    children: [
                        { title: 'Listado', url: '/panel/admin/users' },
                        { title: 'Ganancias', url: '/panel/admin/earnings' },
                    ]
                },
                {
                    title: 'Referidos',
                    icon: 'mdi-braille',
                    children: [
                        { title: 'Listado', url: '/panel/admin/list-referrals' },
                        { title: 'Ganancias', url: '/panel/admin/referrals-earnings'},
                        { title: 'Códigos', url: '/panel/admin/codes-assign-referrals'},
                    ]
                },
                {
                    title: 'Harmonys',
                    icon: 'mdi-vector-link',
                    children: [
                        { title: 'Listado', url: '/panel/admin/harmonys' },
                        { title: 'Estadísticas', url: '/panel/admin/stats' },
                        {title: 'Estadisticas diarias', url: '/panel/admin/daily-stats'},
                        {title: 'Whatsapps y emails', url: '/panel/admin/whatsapp-emails'}
                    ]
                },
                {
                    title: 'Retiros',
                    icon: 'mdi-cash-refund',
                    children: [
                        { title: 'Listado', url: '/panel/admin/withdrawals' },
                        { title: 'Pendientes', url: '/panel/admin/pendings' },
                    ]
                },
                {
                    title:'Medios',
                    icon:'mdi-image-search',
                    children:[
                        { title: 'Listado', url: '/panel/admin/media' },
                        { title: 'Pendientes', url: '/panel/admin/media-pending' },
                    ]

                },
                { title: 'Conversiones', icon: 'mdi-chart-timeline-variant-shimmer', url: '/panel/admin/conversions' },

            ],
            selectedItem: null,
            withdrawalsCountPending: 0,
            mediaCountPending: 0
        };
    },
    watch: {
        $route() {
            this.updateSelectedItem();
        }
    },
    mounted() {
        this.$root.$on('updateMedia', this.countMediaPending);
    },
    beforeDestroy() {
        this.$root.$off('updateMedia', this.countMediaPending);
    },
    methods: {
        updateSelectedItem() {
            const path = this.$route.path;

            // Buscar el item padre o el hijo correspondiente
            const foundItem = this.items.find(item => {
                if (item.children) {
                    // Verifica si hay un hijo cuyo `url` coincida con la ruta actual
                    const foundChild = item.children.find(child => path.startsWith(child.url));
                    if (foundChild) {
                        return true; // Si encuentra un hijo correspondiente, selecciona el padre
                    }
                }

                // Verifica si la ruta es exactamente la del padre (sin tomar rutas hijas como coincidencia)
                return path === item.url; // Solo rutas exactas
            });

            if (foundItem) {
                this.selectedItem = this.items.indexOf(foundItem);
            } else {
                this.selectedItem = null; // Si no hay coincidencia, no selecciona nada
            }
        },

        isExactActive(url) {
            const path = this.$route.path;

            // Convertir la URL dinámica en una expresión regular
            const dynamicUrl = url.replace(/:\w+/g, '[^/]+');


            const regex = new RegExp(`^${dynamicUrl}$`);

            // Verificar si la ruta actual coincide con la ruta dinámica
            return regex.test(path);
        },

        isExactActiveSubmenu(children) {
            const path = this.$route.path;
            return children.some(child => path.startsWith(child.url));
        },

        logout() {
            try {
                axios.get('/logout');
                localStorage.removeItem("impersonate_admin");
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                this.$router.push('/');
            } catch (error) {
                console.error(error);
            }
        },

       async countWithdrawalsPending() {
            const response = await axios.get('/admin/count-withdrawals-pending');
            if(response.data.withdrawalsCount){
                this.withdrawalsCountPending = response.data.withdrawalsCount;
            }
        },

        async countMediaPending(){
            const response = await axios.get('/admin/count-media-pending');
            if(response.data.mediaCount){
                this.mediaCountPending = response.data.mediaCount;
            }
        }
    },
    created() {
        this.updateSelectedItem();
        this.countWithdrawalsPending();
        this.countMediaPending();
    }
};
</script>

<style scoped>

.badge-aligned {
  margin-left: 8px; /* Ajusta el valor según sea necesario */

}
.inactive-item .v-list-item__title {
    color: rgb(99 101 124);
}

.mdi-chevron-down {
    color: rgb(99 101 124);
}

.active-item .links {
    color: white;
}

.links {
    color: rgb(99 101 124);
}

.group-color {
    color: rgb(99 101 124) !important;
}

.menu {
    position: absolute;
    top: 23px;
    left: 22rem;
    z-index: 2;
}

.logo-base {
    color: white !important;
}

.logo {
    width: 11rem;
}

.logo-2 {
    width: 1.5rem;
    height: 2rem;
    margin-right: 2.5rem;
    max-width: 1.5rem !important;
}

.active-item {
    color: white !important;
    border-right-color: #6e4bb9 !important;
    border-right-width: 2px !important;
    background-color: #6e4bb9 !important;
}

.inactive-item {
    color: rgb(99 101 124) !important;
    background-color: transparent !important;
}

.v-list-item:hover .inactive-item {
    background-color: rgba(255, 255, 255, 0.1) !important;
}

.v-list-item--link {
    border-radius: 10px;
}

/* Icon styles */
.active-icon {
    color: white !important;
}

.inactive-icon {
    color: rgb(99 101 124) !important;
}

.v-list-item[aria-selected="true"] {
    background-color: #6e4bb9 !important;
    color: white !important;
}

@media (max-width: 959px) {
    .menu {
        top: 19px;
        left: -12px;
    }
}



</style>

<style>
.background-sidebar .v-navigation-drawer {
    background-color: #171821 !important;
    border-radius: 0px !important;
    border-right-color: #252630;
    border-right-width: 2px;
    border-right-style: solid;
}
</style>
