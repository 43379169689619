<template>
  <v-row no-gutters class="layout-container">
    <Sidebar />
    <div class="content-wrapper">
      <Header />
      <router-view />
    </div>
  </v-row>
</template>

<script>
import Sidebar from '../../components/partials/admin/Sidebard.vue';
import Header from '../../components/partials/admin/Header.vue';

export default {
    components: {
        Sidebar,
        Header
    },
    data() {
        return {
        };
    },
    methods: {
    }
};
</script>

<style scoped>
.layout-container {
  display: flex;
  min-height: 100vh;
  width: 100%;
}

.content-wrapper {
  background-color: white;
  flex: 1; /* Ocupa el espacio restante */
  display: flex;
  flex-direction: column;
  overflow-x: hidden; /* Evita el desbordamiento horizontal */
  min-width: 0; /* Evita que la content-wrapper fuerce su ancho */
}

/* Asegurarte de que el sidebar ocupe su tamaño y no afecte al content-wrapper */
.sidebar {
  flex: 0 0 auto;
}

.background {
  background: #171821 !important;
}

</style>
