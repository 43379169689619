import { render, staticRenderFns } from "./Sidebard.vue?vue&type=template&id=57c540d6&scoped=true"
import script from "./Sidebard.vue?vue&type=script&lang=js"
export * from "./Sidebard.vue?vue&type=script&lang=js"
import style0 from "./Sidebard.vue?vue&type=style&index=0&id=57c540d6&prod&scoped=true&lang=css"
import style1 from "./Sidebard.vue?vue&type=style&index=1&id=57c540d6&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57c540d6",
  null
  
)

export default component.exports